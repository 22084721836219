import React from 'react'
import Navbar from './components/Navbar'
import HeroSection from './components/HeroSection';
import Products from './components/products';


export const App = () => {
  return (
   <>
    <Navbar/>
    <HeroSection/>
    
    
      <Products/>

  
    
   </>
  )
}

export default App;