import React from "react";

import phoneimage1 from '../assets/imgs/iphone15pro.png';
import phoneimage2 from '../assets/imgs/pixel.png';
import phoneimage3 from '../assets/imgs/s24.png';
import phoneimage4 from '../assets/imgs/S23U.png';
import phoneimage5 from '../assets/imgs/Redmi.png';

const products = [
  {
    id: 1,
    name: 'Pixel 8 Pro',
    href: '#',
    price: 'LKR 150000.00',
    imageSrc: phoneimage2,
  },
  {
    id: 2,
    name: 'Iphone 15 Pro',
    href: '#',
    price: 'LKR 250000.00',
    imageSrc: phoneimage1,
  },
  {
    id: 3,
    name: 'S24',
    href: '#',
    price: 'LKR 180000.00',
    imageSrc: phoneimage3,
  },
  {
    id: 4,
    name: 'S24 Ultra',
    href: '#',
    price: 'LKR 250000.00',
    imageSrc: phoneimage4,
  },
  {
    id: 5,
    name: 'REDMI',
    href: '#',
    price: 'LKR 150000.00',
    imageSrc: phoneimage5,
  }
];

export default function Example() {
  return (
    <div className="relative min-h-screen bg-white">      
      {/* Left Sidebar */}
      <div className="absolute top-12 left-0  h-[calc(100vh-3rem)] bg-gray-100 p-4 z-20 hidden md:block">
        {/* Ad 1 */}
        <div className="mb-4">
          <h2 className="text-sm font-semibold mb-2">Ad 1</h2>
          <div className="bg-white p-4 rounded shadow-md">
            <p className="text-xs">Ad Content Here</p>
          </div>
          {/* Ad 2 */}
        <div>
          <h2 className="text-sm font-semibold mb-2">Ad 2</h2>
          <div className="bg-white p-4 rounded shadow-md">
            <p className="text-xs">Ad Content Here</p>
          </div>
        </div>
      </div>

      </div>

      {/* Right Sidebar */}
      <div className="absolute top-12 right-0 w-1/8 h-[calc(100vh-3rem)] bg-gray-100 p-2 z-20 hidden md:block">
        {/* Placeholder for ads */}
        <h2 className="text-sm font-semibold mb-2">Right Sidebar</h2>
        <div className="bg-white p-2 rounded shadow-md">
          <p className="text-xs">Ad Content Here</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="w-full md:w-2/3 p-6 mt-16 mb-16 ml-auto mr-auto">
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">Latest Products</h1>

        <div className="grid grid-cols-2 gap-x-4 gap-y-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 xl:gap-x-6">
          {products.map((product) => (
            <a
              key={product.id}
              href={product.href}
              className="group block relative transform transition-transform duration-500 hover:scale-105 hover:shadow-md"
            >
              <div className="aspect-h-1 aspect-w-1 w-full max-w-xs overflow-hidden rounded-lg bg-white relative border border-gray-300 shadow-sm">
                <img
                  alt={product.name}
                  src={product.imageSrc}
                  className="h-full w-full object-cover object-center transition-opacity duration-300 group-hover:opacity-80"
                />
                <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-opacity duration-300"></div>
              </div>
              <h3 className="text-center mt-2 text-xs font-medium text-gray-700">{product.name}</h3>
              <p className="text-center mt-1 text-sm font-medium text-gray-900">{product.price}</p>
            </a>
          ))}
        </div>
      </div>
      {/* Bottom Ad Bar (Mobile View) */}
      <div className="fixed bottom-0 left-0 w-full bg-gray-100 p-2 z-20 block md:hidden">
        <h2 className="text-sm font-semibold mb-2 text-center">Bottom Ad Bar</h2>
        <div className="bg-white p-2 rounded shadow-md">
          <p className="text-xs text-center">Ad Content Here</p>
        </div>
      </div>
    </div>
    
  );
}
