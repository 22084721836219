import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay, EffectFade } from "swiper/modules";

import image1 from '../assets/imgs/img1.png';
import image2 from '../assets/imgs/img2.png';
import image3 from '../assets/imgs/img3.png';
import image4 from '../assets/imgs/img4.png';

const HeroSection = () => {
  const images = [
    { src: image1, caption: "Amazing Deals on Phones!" },
    { src: image2, caption: "Latest Technology at Your Fingertips" },
    { src: image3, caption: "Unbeatable Prices Only Here!" },
    { src: image4, caption: "" }
  ];

  return (
    <div className="hero-section relative h-[200px] md:h-[450px] lg:h-[600px]">
      <Swiper
        modules={[Pagination, Autoplay, EffectFade]} 
        pagination={{ clickable: true }}              
        autoplay={{ delay: 2000 }}
        loop
        effect="slide"
        className="w-full h-full"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className="relative">
            {/* Background Image */}
            <div className="w-full h-full">
              <img src={image.src} alt={`Slide ${index + 1}`} className="w-full h-full object-cover" />
            </div>

            {/* Dark Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

            {/* Caption Text */}
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white px-4">
              <h2 className="text-2xl md:text-4xl lg:text-6xl font-bold mb-4 animate-fadeIn">
                {image.caption}
              </h2>
              <p className="text-sm md:text-xl lg:text-2xl max-w-xl">
                Shop the latest phones with exclusive deals and discounts. Available now!
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroSection;
