import React, { useState } from 'react';
import { navItems } from "../constants";
import { Menu, X, Search } from "lucide-react"; 
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false); // To handle search icon toggle in mobile view

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  return (
    <nav className="sticky top-0 z-50 py-3 bg-white border-b border-gray-300">
      <div className="container px-4 mx-auto relative text-sm">
        <div className="flex text-start items-center">
          
          <div className="flex items-center flex-shrink-0">
            <Link to="/" className="h-10 text-4xl font-medium antialiased group">
              <span className="group-hover group-hover:px-2 transition duration-300">
                <span className="text-black">Phone</span>
                <span className="text-rose-700">Deals</span>
              </span>
            </Link>
          </div>

          {/* Navigation items for larger screens */}
          <ul className="font-medium hidden lg:flex ml-8 space-x-12">
            {navItems.map((item, index) => (
              <li key={index}>
                <a 
                  href={item.href}
                  className="hover:text-rose-700 hover:underline transition duration-300"
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
          
          {/* Search Bar */}
          <div className=" w-96 hidden lg:flex items-center justify-end">
            <input 
              type="text" 
              className="w-full border h-12 shadow p-4 rounded-lg ms-8" 
              placeholder="Search" 
            />
          </div>
          
          {/* Search icon on mobile view */}
          <div className="lg:hidden flex items-center ml-auto">
            <button onClick={toggleSearch} className="mr-4">
              <Search className="w-6 h-6 text-gray-600" />
            </button>
            <button onClick={toggleNavbar}>
              {mobileDrawerOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>

        {/* Mobile search input */}
        {searchOpen && (
          <div className="w-full mt-4 lg:hidden">
            <input 
              type="text" 
              className="w-full border h-12 shadow p-4 rounded-lg mr-4" // Added margin-right
              placeholder="Search..." 
            />
          </div>
        )}
        
        {/* Mobile drawer for navigation */}
        {mobileDrawerOpen && (
          <div className="fixed right-0 top-0 z-20 bg-white w-full p-12 flex flex-col justify-center items-end lg:hidden"> {/* Aligned to right corner */}
          <button onClick={toggleNavbar} className="absolute top-4 right-4"> {/* Ensure the X button is visible */}
              <X className="w-6 h-6 text-gray-600" />
            </button>
            <div className="flex items-center mb-">
              <Link to="/" className="space-y-4 mt-2 w-full ">
                <span className="group-hover:px-2 transition duration-300">
                  <span className="w-full text-center hover:text-rose-700 hover:underline transition duration-300">Home</span>
                </span>
              </Link>
              </div>
            <ul className="space-y-4 mt-4 w-full  ">

              {navItems.map((item, index) => (
                <li key={index} className="w-full text-right hover:text-rose-700 hover:underline transition duration-300"> {/* Align each menu item to the right */}
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
