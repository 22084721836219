import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Brands from './components/Brands';
import Navbar from './components/Navbar';
import Stores from './components/Stores';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/Brands.jsx",
    element: <div>
      <Navbar/>
      
    <Brands/>
  
    </div>,
  },
  {
    path: "/Stores.jsx",
    element: <div>
      <Navbar/>
      
    <Stores/>
  
    </div>,
  },
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router = {router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
